import { all } from 'redux-saga/effects';
import configureRaven from './configureRaven';
import handleError from './handleError';
import fetchGeoLocation from './fetchGeoLocation';
import fetchGradeOptions from './fetchGradeOptions';
import fetchPlans from './fetchPlans';
import selectPlan from './selectPlan';
import selectServiceLevel from './selectServiceLevel';
import selectBillingCycle from './selectBillingCycle';
import removePlan from './removePlan';
import updateTotals from './updateTotals';
import logActions from './logActions';
import orderFinish from './orderFinish';
import enroll from './enroll';
import setParentInfo from './setParentInfo';
import setPassword from './setPassword';
import fetchProducts from './fetchProducts';
import setDataLayerExperimentId from './setDataLayerExperimentId';
import submitParentInformationForm from './submitParentInformationForm';
import submitStudentInformationForm from './submitStudentInformationForm';
import submitStoreStudentInformationForm from './submitStoreStudentInformationForm';
import checkout from './checkout';
import login from './login';
import setBrainTreeToken from './setBrainTreeToken';
import paypal from './paypal';
import googlePay from './googlePay';
import applePay from './applePay';
import setPricing from './setPricing';
import fetchCouponCode from './fetchCouponCode';
import applyEnrollCouponCode from './applyEnrollCouponCode';

export default function* rootSaga() {
  yield all([
    configureRaven(),
    handleError(),
    fetchPlans(),
    fetchGeoLocation(),
    fetchGradeOptions(),
    selectPlan(),
    removePlan(),
    updateTotals(),
    orderFinish(),
    enroll(),
    logActions(),
    setParentInfo(),
    setPassword(),
    setDataLayerExperimentId(),
    submitParentInformationForm(),
    submitStudentInformationForm(),
    submitStoreStudentInformationForm(),
    selectServiceLevel(),
    selectBillingCycle(),
    fetchProducts(),
    checkout(),
    login(),
    setBrainTreeToken(),
    paypal(),
    googlePay(),
    applePay(),
    setPricing(),
    fetchCouponCode(),
    applyEnrollCouponCode(),
  ])
}
