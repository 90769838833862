// NOTE: max-len lint rule is disabled since this page has copy as single-line
// string literals.
/* eslint-disable max-len */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { reduxForm, Field } from 'redux-form';
import asyncValidate from './asyncValidate';
import syncValidate from './syncValidate';
import RegistrationTextInput from '../../../RegistrationTextInput';
import RegistrationSelectInput from '../../../RegistrationSelectInput';
import routerHistory from '../../../../constants/routerHistory';
import PhoneNumberInput from '../../../PhoneNumberInput';

const studentappUrl = process.env.REACT_APP_STUDENT_APP_URL || 'https://studentapp.hellothinkster.com';

class UserRegistration extends Component {
  componentDidMount() {
    window.location.href = 'https://hellothinkster.com/get-plans-price.html';
    if (window !== undefined) window.scrollTo(0, 0);

    const { defaultBillingCycle } = this.props;
    let selectedBillingCycle = this.props.selectedBillingCycle && this.props.selectedBillingCycle ? this.props.selectedBillingCycle : defaultBillingCycle;
    let selectedService = this.props.selectedService
    && this.props.selectedService.length > 0
    && this.props.selectedService[0]
    && this.props.selectedService[0].selectedService ? this.props.selectedService[0].selectedService : 'Gold';

    const params = new URLSearchParams(this.props.location.search);
    
    // Select Experiment
    let experiment = params.get('utm_expid');
    let subExperimentId = params.get('utm_sub_expid');
    if (experiment) {
      this.props.onSelectExperiment(experiment);
      this.props.setGeoLocation(this.props.geoLocation);
    }
    if (subExperimentId) {
      this.props.onSelectSubExperiment(subExperimentId);
    }

    // Query Code - Set Billing Cycle to 12
    let code = params.get('code');
    let rsCode = params.get('rsCode');
    if (code) {
      this.props.blur('referralCode', code);
      if (code.toLowerCase() === 'corp2018') {
        this.props.onSelectBillingCycle(6);
      } else if (code.toLowerCase() === 'holidays18') {
        this.props.onSelectBillingCycle(3);
      } else if (code.toLowerCase() === 'whmcon') {
        this.props.onSelectBillingCycle(3);
      }
    }
    if (rsCode) {
      this.props.blur('referralCode', rsCode);
    }

    // RS Code - Old Compatability
    let email = params.get('email');
    let name = params.get('name');
    let utmSource = params.get('utm_source');
    let phoneNumber = params.get('phone_number');
    const urlParams = { email: '', fn: '', ln: '', utmSource: '' };
    
    if (this.props.urlParameters && this.props.urlParameters.email) {
      this.props.blur('email', email);
    } else if (email) {
      urlParams.email = email;
      this.props.blur('email', email);
    }
    if (this.props.urlParameters && this.props.urlParameters.fn) {
      this.props.blur('firstName', this.props.urlParameters.fn);
    } else if (name) {
      let split = name.split(' ');
      if (split.length > 0) {
        urlParams.fn = split[0];
        this.props.blur('firstName', urlParams.fn);
      }
    }
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace('+', '');
      phoneNumber = phoneNumber.trim();
      if (phoneNumber[0] !== '1') {
        phoneNumber = '1' + phoneNumber;
      }
      this.props.blur('phoneNumber', phoneNumber)
    }
    if (this.props.urlParameters && this.props.urlParameters.ln) {
      this.props.blur('lastName', this.props.urlParameters.ln);
    } else if (name) {
      let split = name.split(' ');
      if (split.length > 1) {
        urlParams.ln = split[1];
        this.props.blur('lastName', urlParams.ln);
      }
    }
    if (utmSource) {
      urlParams.utmSource = utmSource;
    }
    this.props.onSelectURLParameters(urlParams); 
    
    // Override from Query
    let ss = params.get('ss');
    if (ss) {
      selectedService = ss.toLowerCase().charAt(0).toUpperCase() + ss.slice(1);
    }

    window.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        checkout: { actionField: { step: 1 } },
        impressions: { category: selectedService },
      },
    });

    // Override
    if (selectedService === 'Lite') {
      selectedBillingCycle = 1;
    }

    // Override
    if (selectedService === 'Concierge') {
      selectedBillingCycle = 12;
    }

    const { geoLocation } = this.props;
    // Only for Australia
    if (geoLocation && geoLocation.country_code && geoLocation.country_code === 'IN') {
      this.props.onSelectBillingCycle(defaultBillingCycle);
      this.props.onEnrollService({ defaultBillingCycle });
    } else {
      this.props.onSelectBillingCycle(selectedBillingCycle);
      this.props.onEnrollService({ selectedService, selectedBillingCycle });
    }
  }

  componentDidUpdate(preProps) {
    routerHistory.listen((newLocation, action) => {
      if (
        window.location 
        && window.location.pathname 
        && window.location.pathname === '/step-1' 
        && newLocation 
        && newLocation.pathname 
        && (
          newLocation.pathname === '/step-3'
        )) {
        routerHistory.push({
          pathname: '/',
          search: window.location.search,
        });
        this.props.clearStudents();
      }
    });
  }

  render() {
    const {
      handleSubmit,
      invalid,
      submitting,
      referralCodeInputValueMetadata,
      parentInformationFormErrors,
      geoLocation
    } = this.props;

    // Default Service - Gold
    const selectedService = this.props.selectedService
    && this.props.selectedService.length > 0
    && this.props.selectedService[0]
    && this.props.selectedService[0].selectedService ? this.props.selectedService[0].selectedService : 'Gold';

    let trialDuration = '1-WEEK';
    if (referralCodeInputValueMetadata && referralCodeInputValueMetadata.numberOfTrialDaysAfterNewPayment) {
      trialDuration = `${referralCodeInputValueMetadata.numberOfTrialDaysAfterNewPayment}-DAYS`;
    }

    let submitText = 'Go to Step 2: Share Student Information';
    if (selectedService === 'Lite') {
      submitText = 'Get Started with Lite Plan';
    } else if (selectedService === 'Concierge') {
      submitText = `START ZERO $ FREE ${trialDuration} CONCIERGE TRIAL`;
    }
    return (
      <div className={`o-userRegistration ${this.props.loading ? 'b-blocker b-blocker--loading' : ''}`}>
        <Helmet
          title="Thinkster Math Parent Registration"
          meta={[
            { name: 'description', content: 'Become a Thinkster Math parent by creating an account.' },
          ]}
        />
        {/*
          '.b-blocker' element added
          - append with 'b-blocker--loading' class to turn on loading blocker
        */}
        <header className="b-formHeader b-formHeader--noBorder">
          <h1 className="a-h(28)">
            {selectedService === 'Gold' ? 'Step 1: Create Your Account' : 'Step 1: Create Your Account' }
          </h1>
          <div className="progress">
            <div className="progress-bar" role="progressbar"  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div className="progress-test">0% complete</div>
        </header>
        <form onSubmit={handleSubmit} id="reg-account-info">
          <div className="b-formBlock">
            {/*
              The '.b-formTextInput' element is a standard form input box
            */}

            { parentInformationFormErrors && parentInformationFormErrors.emailAlreadyExists ?
              <div className="b-formBlock__set">
                <div className="b-formBlock__item">
                  <div className="b-custom-error__wrapper b-custom-error__wrapper--on">
                      Seems like you already have an account with us using this email address. <br /><a href={studentappUrl} target="_blank" rel="noreferrer">Click here to Login</a> to your existing account. Or create a new account using different email address.
                  </div>
                </div>
              </div> : '' }

            <div className="b-formBlock__set">
              {/* big-input-new */}
              <div className="b-formBlock__item b-formBlock__item--1-2">
                <Field
                  component={RegistrationTextInput}
                  name="firstName"
                  placeholder="Parent&rsquo;s First Name"
                  type="text"
                  autocomplete="given-name"
                />
              </div>
              {/* big-input-new */}
              <div className="b-formBlock__item b-formBlock__item--1-2">
                <Field
                  component={RegistrationTextInput}
                  name="lastName"
                  placeholder="Parent&rsquo;s Last Name"
                  type="text"
                  autocomplete="family-name"
                />
              </div>
            </div>
            <div className="b-formBlock__set b-custom-pophover">
              {/* big-input-new */}
              <div className="b-formBlock__item">
                <Field
                  component={RegistrationTextInput}
                  name="email"
                  placeholder="Primary Email (this will be your login)"
                  type="email"
                  autocomplete="email"
                />
              </div>
            </div>
            <div className="b-formBlock__set">
              {/* big-input-new */}
              <div className="b-formBlock__item">
                <Field
                  component={PhoneNumberInput}
                  name="phoneNumber"
                  placeholder="Mobile Phone Number (so the coach can contact you)"
                  countryCode={geoLocation.country_code ? geoLocation.country_code : 'US'}
                />
              </div>
            </div>
            <div className="b-formBlock__set">
              {/* big-input-new */}
              <div className="b-formBlock__item">
                <Field
                  component={RegistrationTextInput}
                  name="password"
                  placeholder="Password (to access your account)"
                  type="password"
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div className="b-formBlock__set">
              <div className="b-formBlock__item">
                <Field
                  component={RegistrationTextInput}
                  name="referralCode"
                  placeholder="Add a Referral Code (if a friend referred you)"
                  type="text"
                />
              </div>
            </div>
            <div className="b-formBlock__set">
              {/* big-input-new */}
              <div className="b-formBlock__item">
                <Field
                  component={RegistrationSelectInput}
                  name="source"
                  placeholder="How did you hear about us?"
                  type="text"
                  data={[
                    '',
                    'App Store',
                    'Facebook',
                    'Existing Thinkster Customer',
                    'Friend / Word of Mouth',
                    'Google',
                    'Math Insider',
                    'Other',
                  ]}
                />
              </div>
            </div>
            {  (invalid && parentInformationFormErrors) ?
              <div className="b-formBlock__set">
                <div className="b-formBlock__item" style={{ "color": "red", textAlign: "center", marginBottom: "0"}}>
                  * Please complete all mandatory fields.
                </div>
              </div> : ''
            }

          </div>
          <footer className="b-formFooter">
            <button
              disabled={submitting}
              className={submitText === 'Start My Free Trial' ? 'abc b-button b-button--med b-blocker__trigger big-btn-new' : 'b-button b-button--med b-blocker__trigger'} type="submit" title="Next" style={{  marginBottom: "5px"}}
            >
              <span className="b-button__label">
                {submitText}
              </span>
            </button>
            <p className="a-p(14) a-justify(center)">
              By submitting, you agree to our
              <a
                href="https://www.hellothinkster.com/terms.html"
                target="_blank"
                rel="noreferrer"
                title="Terms of Service"
              >
                &nbsp;terms of service
              </a>
              &nbsp;and our
              <a
                href="https://www.hellothinkster.com/privacy.html"
                target="_blank"
                rel="noreferrer"
                title="Privacy Policy"
              >
                &nbsp;privacy policy
              </a>
              .
            </p>
          </footer>
        </form>

      </div>
    );
  }
}

export const formName = 'ParentInformationForm';

export default reduxForm({
  asyncValidate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: formName,
  validate: syncValidate,
})(UserRegistration);
