/* eslint-disable no-constant-condition */
import {
  put,
  select,
  take,
  fork
} from 'redux-saga/effects';
import axios from 'axios';
import Cookies from 'cookies-js';
import loadingAction from '../actions/loading';
import errorAction from '../actions/error';
import { SUBMIT_STUDENT_INFORMATION_FORM } from '../actions/submitStudentInformationForm';
import selectStudents from '../selectors/students';
import routerHistory from '../constants/routerHistory';
import { selectPlan } from '../actions/selectPlans';
import selectSession from '../selectors/session';
import selectSelectedService from '../selectors/selectedService';
import selectSelectedBillingCycle from '../selectors/selectedBillingCycle';
import selectGeoLocation from '../selectors/geoLocation';
import selectPlans from '../selectors/plans';
import { setStudentInfo } from '../actions/studentInfo';
import { setServiceLevels } from '../actions/serviceLevels';
import constants from '../constants/defaultRegistrationFlow';
import selectParent from '../selectors/parentInfo';
import * as gradeUtil from '../utils/grade';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';
const demoAppURL = process.env.DEMO_APP_URL || 'https://demo.hellothinkster.com';
const cbBaseURL = process.env.REACT_APP_CB_API_BASE_URL || 'https://chargebee-api.hellothinkster.com/api';

function* trackCampaign(paylod, sessionToken) {
  try {
    yield axios({
      method: 'post',
      baseURL,
      url: '/v1/campaigntrackings',
      headers: { Authorization: `JWT ${sessionToken}` },
      data: paylod,
    });
  } catch (error) {
    // return error;
  }
}

export default function* submitStudentInformationFormSaga() {

  while (true) {
    yield take(SUBMIT_STUDENT_INFORMATION_FORM);
    try {
        yield put(loadingAction(true));
        const students = yield select(selectStudents);
        const selectedService = yield select(selectSelectedService);
        const session = yield select(selectSession);
        const sessionToken = session.token;
        const sessionUserId = session.userId;
        const plans = yield select(selectPlans);
        const selectedBillingCycle = yield select(selectSelectedBillingCycle);
        const geoLocation = yield select(selectGeoLocation);

        // Common for All Students
        let service = selectedService[0].selectedService;
        window.dataLayer.push({ event: 'addChild' });

        for (let i = 0; i < students.length; i++) {
            const student = students[i];
            //students[i]['parent_sf_id'] = parent.parent_sf_id;
            let selectedGradeLevel = gradeUtil.range(student.grade);

            // Override for India
            if(geoLocation && geoLocation.country_code && geoLocation.country_code === "IN"){
              selectedGradeLevel = '1 - 6';
            }
            yield put(selectPlan({selectedGradeLevel:selectedGradeLevel,selectedService:service,name:student.first_name}));
        }

        // Create Students
        const savedStudents =
          yield axios.all(createStudents(sessionUserId, sessionToken, students, plans, selectedService,selectedBillingCycle,geoLocation));

        // Request Service Levels if Students are created Successfully
        if(typeof savedStudents !== "undefined" && savedStudents !== null &&
          savedStudents.length !== null && savedStudents.length > 0){
          var url = `/get_order_summary?parent_id=${sessionUserId}`;
          const serviceLevels = yield  axios({
              method: 'get',
              baseURL: cbBaseURL,
              url
          });
          if(serviceLevels && serviceLevels.data){
            yield put(setServiceLevels(serviceLevels.data));
          }
        }

        // Get Only Data
        let studentInfo = [];
        studentInfo = savedStudents.map((student) => student.data);

        window.dataLayer.push({
          event: 'students_account_created',
          students: studentInfo,
        });

        window.dataLayer.push({ parent_id: sessionUserId });

        // Save
        yield put(setStudentInfo(studentInfo));

        // Get Current URL
        yield put(loadingAction(false));

        // Try Hotjar
        try {
          if (window && window.hj) {
            window.hj('formSubmitSuccessful');
          }
        } catch (error) {
          // ...
        }

        // Campaign tracking
        try {

          const utmSource = Cookies.get('utm_source');
          const utmMedium = Cookies.get('utm_medium');
          const utmCampaign = Cookies.get('utm_campaign');
          const utmContent = Cookies.get('utm_content');
          const irclickid = Cookies.get('irclickid');
          const fbclid = Cookies.get('fbclid');
          const pageVisits = Cookies.get('__thinkster_page_visits');

          const fbc = Cookies.get('_fbc');
          const fbp = Cookies.get('_fbp');

          if (utmSource || utmMedium || utmCampaign || utmContent || irclickid || fbc || fbp || fbclid || pageVisits) {
            const payload = {};
            payload.utm_source = utmSource;
            payload.utm_medium = utmMedium;
            payload.utm_campaign = utmCampaign;
            payload.utm_content = utmContent;
            payload.irclickid = irclickid;
            payload.fbc = fbc;
            payload.fbp = fbp;
            payload.fbclid = fbclid;
            payload.page_visits = pageVisits;
            payload.user_id = sessionUserId;
            payload.created_by = 'api';
            payload.tracking_source = window.location.href;

            yield fork(trackCampaign, payload, sessionToken); // async dispatch
          }
        } catch (e) {

        }

        // Check Free Trial Coupon
        const parent = yield select(selectParent);

        // Check if Registration Flow is present
        let path = '/step-3';
        if(parent && parent.mode_of_payment  && (parent.mode_of_payment.toLowerCase() === 'prepaid' || parent.mode_of_payment.toLowerCase() === 'demo')
          ){
          routerHistory.push({
            pathname: '/ref-thank-you',
            search: window.location.search,
          });
          

        } else if(url.indexOf(constants.REGISTRATION_FLOW_ID) !== -1){

          path = demoAppURL + '/tour?token=' + sessionToken + '&id=' + sessionUserId;
          path = path.replace(/(^\s+|\s+$)/g, '');
          window.location.href = path;

        } else {

          routerHistory.push({
            pathname: path,
            search: window.location.search,
          });
        }

    } catch (error) {
      yield put(loadingAction(false));
      yield put(errorAction({
        error,
        title: 'Error submitting student information form',
      }));
      // Try Hotjar
      try {
        if (window && window.hj) {
          window.hj('formSubmitFailed');
        }
      } catch (error) {
        // ...
      }
    }
  }
}

function createStudents(sessionUserId, sessionToken, students, plans, selectedService, selectedBillingCycle, geoLocation) {
  const requests = [];
  for (let i = 0; i < students.length; i++) {
    const student = students[i];
    let first_name = student.first_name;
    let last_name = student.last_name;
    if(!(last_name && last_name.trim())) {
      let fullName = first_name;
      first_name = fullName.split(" ")[0];
      last_name = fullName.substring(first_name.length).trim();
    }
    let service = selectedService[0].selectedService;
    let selectedGradeLevel = gradeUtil.range(student.grade);;

    // Override for India
    if(geoLocation && geoLocation.country_code && geoLocation.country_code === "IN"){
       selectedGradeLevel = '1 - 6';
    }
    selectedBillingCycle = selectedBillingCycle ? parseInt(selectedBillingCycle) : 1;
    const selectedPlan = plans && plans[selectedGradeLevel] && plans[selectedGradeLevel][service] ? plans[selectedGradeLevel][service][selectedBillingCycle] : {};
    requests.push(axios({
      method: 'post',
      baseURL,
      url: `/v1/parents/${sessionUserId}/students`,
      headers: { Authorization: `JWT ${sessionToken}` },
      data: {
        first_name: first_name,
        last_name: last_name,
        gender: '',
        pronouns: student.gender,
        braintree: { addon_id: selectedPlan._id,
                    billingFrequency: 1,
                    selectedGradeLevel },
        service_id: selectedPlan.cf_service_id,
        session_type: selectedPlan.cf_session_type || '1:1',
        grade: student.grade,
        sf_student_gender: student.gender
        //parent_sf_id: student.parent_sf_id
      },
    }));
  }

  return requests;
}
