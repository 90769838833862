import { SET_DEFAULT_TRIAL_PLAN } from '../actions/defaultTrialPlan';

export default function plans(
  state = 'Gold',
  action
) {
  switch (action.type) {
    case SET_DEFAULT_TRIAL_PLAN:
      return action.payload;
    default:
      return state;
  }
}
