/* eslint-disable no-constant-condition */
import axios from 'axios';
import {
  select,
  take,
  put,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { GOOGLE_PAY } from '../actions/googlePay';
import loadingAction from '../actions/loading';
import selectSession from '../selectors/session';
import selectSelectedService from '../selectors/selectedService';
import selectParent from '../selectors/parentInfo';
import selectNonce from '../selectors/nonce';
import routerHistory from '../constants/routerHistory';
import studentInfo from '../selectors/studentInfo';
import * as PaymentActions from '../actions/payment';
import selectEnrollCouponCode from '../selectors/enrollCouponCode'

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';
const cbBaseURL = process.env.REACT_APP_CB_API_BASE_URL || 'https://chargebee-api.hellothinkster.com/api';
const googlePayFormName = 'GooglePayInformationForm';

export default function* submitGooglePaySaga() {
  while (true) {
    yield take(GOOGLE_PAY);
    try {
      const selectedService = yield select(selectSelectedService);
      const session = yield select(selectSession);
      const sessionUserId = session.userId;
      const nonce = yield select(selectNonce);

      const service = selectedService && selectedService[0].selectedService ? selectedService[0].selectedService : 'Gold';
      const couponCode = yield select(selectEnrollCouponCode);
      
      yield put(loadingAction(true));
      yield put(PaymentActions.googlePayRequesting());
      yield put(startSubmit(googlePayFormName));

      const paymentMethodsResponse = yield axios({
        method: 'post',
        baseURL,
        url: '/v1/paymentmethods',
        data: { userId: session.userId, nonceFromTheClient: nonce, app: "enroll", payment_method: "google_pay" },
        headers: { Authorization: `JWT ${session.token}` },
      });

      const savedStudents = yield select(studentInfo);
      const studentIds = [];
      savedStudents.map((student) => studentIds.push(student.id));

      // Get Current URL
      if (paymentMethodsResponse && paymentMethodsResponse.data.paymentMethod.token) {
        yield axios({
          method: 'post',
          baseURL: cbBaseURL,
          url: '/create_payment_method',
          data: {
            customer_id: sessionUserId,
            token: paymentMethodsResponse.data.paymentMethod.token,
            type: 'google_pay',
            coupon_code: couponCode ? couponCode.code : ''
          },
        });

      }

      yield put(stopSubmit(googlePayFormName));
      yield put(loadingAction(false));

      // Try Hotjar
      try {
        if (window && window.hj) {
          window.hj('formSubmitSuccessful');
        }
      } catch (error) {
        // ...
      }

      // Check if Registration Flow is present
      let path = '/thank-you-web-sa';
      if (service === 'Lite') {
        path = '/thank-you-lite';
      }

      routerHistory.push({
        pathname: path,
        search: window.location.search,
      });
    } catch (error) {
      const session = yield select(selectSession);
      const sessionToken = session.token;
      const sessionUserId = session.userId;
      const parent = yield select(selectParent);
      let parentEmail;
      if (parent && parent.email_address) {
        parentEmail = parent.email_address;
      }
      const data = {
        subject: 'Registration flow - Payment failed Notification!!!',
        body: `User ID: ${sessionUserId}\n` +
              `User Email-ID: ${parentEmail}\n` +
              `error: ${JSON.stringify(error)}\n`,
        user: sessionUserId,
        email: 'info@hellothinkster.com',
        error: JSON.stringify(error),
        cc: ['rupa@hellothinkster.com', 'kendra@hellothinkster.com', 'karthik@hellothinkster.com', 'kiran@hellothinkster.com', 'nikhil@hellothinkster.com'],
      };
      yield axios({
        method: 'post',
        baseURL,
        url: 'v1/zendesk/ticket',
        headers: { Authorization: `JWT ${sessionToken}` },
        data,
      });

      if (window !== undefined) {
        window.scrollTo(0, 0);
      }
      yield put(loadingAction(false));
      yield put(stopSubmit(googlePayFormName));
      yield put(PaymentActions.googlePayError(error));

      // Try Hotjar
      try {
        if (window && window.hj) {
          window.hj('formSubmitFailed');
        }
      } catch (error) {
        // ...
      }

    }
  }
}
