import { ADD_TO_STORE_CART, STORE_CART_ADD_DISCOUNT } from '../actions/storeCart';

export default function storeCart(
  state = {
    items: [],
    total: 0,
    discount: 0,
    discountPercent: 0,
    currency_unit: "$"
  },
  action
) {
  switch (action.type) {
    case ADD_TO_STORE_CART:
      state.items.push(action.payload);
      let totalAmount = 0;
      state.items.map((item) => {
        totalAmount += item.price;
        return null;
      })
      return { ...state, total: totalAmount };
    case STORE_CART_ADD_DISCOUNT:
        totalAmount = 0;
        state.items.map((item) => {
          totalAmount += item.price;
          return null;
        })
        let discountType = 'percentage';
        let percetangeDiscount = 0;
        let discountAmount = 0;
        let totalAfterDiscount = 0;
        if (action.payload.type) {
          discountType = action.payload.type
        }
        if (discountType === 'percentage') {
          percetangeDiscount = action.payload.discount;

          discountAmount = (totalAmount * percetangeDiscount) / 100.0;
          discountAmount = discountAmount.toFixed(2);
          totalAfterDiscount = totalAmount - discountAmount;
        } else {
          discountAmount = action.payload.discount;
          totalAfterDiscount = totalAmount - discountAmount;
        }


        return { ...state, total: totalAfterDiscount, discount: discountAmount, discountPercent: percetangeDiscount };
    default:
      return state;
  }
}
