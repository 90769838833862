/* eslint-disable no-constant-condition */
import { select, take, put, fork } from "redux-saga/effects";
// import cookie from 'cookie';
import Cookies from "cookies-js";
import axios from "axios";
import errorAction from "../actions/error";
import loadingAction from "../actions/loading";
import setBraintreeClientToken from "../actions/setBraintreeClientToken";
import setSession from "../actions/setSession";
import { SUBMIT_PARENT_INFORMATION_FORM } from "../actions/submitParentInformationForm";
import selectParentInformationFormValues from "../selectors/parentInformationFormValues";
import selectGeoLocation from "../selectors/geoLocation";
import routerHistory from "../constants/routerHistory";
import constants from "../constants/defaultRegistrationFlow";
import selectSession from "../selectors/session";
import selectStudents from "../selectors/students";
import selectSelectedService from "../selectors/selectedService";
import selectSelectedBillingCycle from "../selectors/selectedBillingCycle";
import selectPlans from "../selectors/plans";
import selectSelectedCTA from "../selectors/selectedCTA";
import selectedExperiment from "../selectors/experiments";
import { setStudentInfo } from "../actions/studentInfo";
import { selectPlan } from "../actions/selectPlans";
import { setParentInfo } from "../actions/parentInfo";
import storeCart from "../selectors/storeCart";
import { storeCartAddDiscount } from "../actions/storeCart";
import * as gradeUtil from "../utils/grade";
import _ from "lodash";
import selectedSubExperiment from '../selectors/subExperiment';
import analytics from "../actions/analytics";

const baseURL = process.env.REACT_APP_API_BASE_URL || "https://enroll-api.hellothinkster.com";

function* trackCampaign(paylod, sessionToken) {
  try {
    yield axios({
      method: "post",
      baseURL,
      url: "/v1/campaigntrackings",
      headers: { Authorization: `JWT ${sessionToken}` },
      data: paylod,
    });
  } catch (error) {
    // return error;
  }
}

export default function* submitParentInformationFormSaga() {
  while (true) {
    yield take(SUBMIT_PARENT_INFORMATION_FORM);
    yield put(loadingAction(true));
    const parentInformationFormValues = yield select(
      selectParentInformationFormValues
    );
    const geoLocation = yield select(selectGeoLocation);
    const selectedService = yield select(selectSelectedService);
    const selectedBillingCycle = yield select(selectSelectedBillingCycle);
    const cta = yield select(selectSelectedCTA);
    let experimentId =  yield select(selectedExperiment);
    if (!experimentId) {
      experimentId = Cookies.get("utm_expid");
    }
    let subExperimentId = yield select(selectedSubExperiment);
    if (!subExperimentId) {
      subExperimentId = Cookies.get("utm_sub_expid");
    }

    let lsqProspectId = "";
    lsqProspectId = Cookies.get("MXCProspectId"); // Leadsquared lead id

    if (!lsqProspectId) {
      if (window && window.MXCProspectId) {
        lsqProspectId = window.MXCProspectId;
      }
    }

    if (window && window.dataLayer) {
      // NOTE: window.dataLayer is an observed array that must be mutated...
      window.dataLayer.push({ email: parentInformationFormValues.email });
      window.dataLayer.push({ zip: parentInformationFormValues.postalCode });
    }

    // Common for All Students
    let service =
      selectedService &&
      _.isArray(selectedService) &&
      selectedService.length > 0
        ? selectedService[0].selectedService
        : "Gold";
    let billingCycle = selectedBillingCycle ? selectedBillingCycle : 12;
    let referralCode = parentInformationFormValues.referralCode;
    
    const data = {
      experiment_id: experimentId,
      sub_experiment_id: subExperimentId || null,
      email: parentInformationFormValues.email,
      password: parentInformationFormValues.password,
      first_name: parentInformationFormValues.firstName,
      last_name: parentInformationFormValues.lastName,
      registration_source: "web",
      phone: parentInformationFormValues.phoneNumber || "703-732-7030",
      zip: parentInformationFormValues.postalCode,
      code: referralCode,
      source: parentInformationFormValues.source,
      country: geoLocation.country_code || "US",
      registration_flow_id: parentInformationFormValues.registration_flow_id,
      service,
      billingCycle,
      url: window.location.href,
      cta: cta.startFreeTrialCTA,
      lsqProspectId: lsqProspectId,
      enroll_timezone: geoLocation.time_zone && geoLocation.time_zone.id ? geoLocation.time_zone.id : '',
    };
    try {
      // Override Phone Number if not provided
      const createAccountResponse = yield axios({
        method: "post",
        baseURL,
        url: "/v1/parents",
        data,
      });
      const createAccountResult = createAccountResponse.data;

      // if (typeof _paq !== 'undefined') {
      //   _paq.push(['setUserId', createAccountResult.id]);
      // }

      // if (window && typeof window.FS !== 'undefined' && data && data.email && createAccountResult && createAccountResult.id) {
      //   window.FS.identify(createAccountResult.id, {
      //     email: data.email,
      //   });
      // }

      if (window && window.clarity && data && data.email  && createAccountResult && createAccountResult.id) {
        window.clarity("identify", data.email, createAccountResult.id, "Enroll")
      }
      

      
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: 'parent_account_created',
          account_info: createAccountResult,
        });
        // NOTE: window.dataLayer is an observed array that must be mutated...
        window.dataLayer.push({
          event: 'authentication',
          authType: 'Sign Up',
          uid: createAccountResult.id,
        });
      }

      const createSessionResponse = yield axios({
        method: "post",
        baseURL,
        url: "/v1/sessions",
        data: {
          username: parentInformationFormValues.email,
          password: parentInformationFormValues.password,
        },
      });
      const createSessionResult = createSessionResponse.data;
      const sessionToken = createSessionResult.token;
      const sessionUserId = createSessionResult.user_id;

      // Set app session...
      yield put(
        setSession({
          token: sessionToken,
          userId: sessionUserId,
        })
      );
      const analyticsData = {
        user_id: sessionUserId,
        user_email_address: parentInformationFormValues.email,
        user_phone: parentInformationFormValues.phoneNumber,
        user_first_name: parentInformationFormValues.firstName,
        user_last_name: parentInformationFormValues.lastName,
        user_address_country: geoLocation.country_code || "US",
        user_address_postal_code: geoLocation.zip
      };
      if (window && window.dataLayer) {
        window.dataLayer.push(analyticsData);
      }
      yield put(analytics(analyticsData));
      // Try Hotjar
      try {
        if (window && window.hj) {
          const userId = createSessionResult.user_id;
          window.hj("identify", userId, {});
          window.hj("formSubmitSuccessful");
        }
      } catch (error) {
        // ...
      }

      // Campaign tracking
      try {
        const utmSource = Cookies.get("utm_source");
        const utmMedium = Cookies.get("utm_medium");
        const utmCampaign = Cookies.get("utm_campaign");
        const utmContent = Cookies.get("utm_content");
        const irclickid = Cookies.get("irclickid");
        const fbclid = Cookies.get("fbclid");
        const pageVisits = Cookies.get("__thinkster_page_visits");

        const fbc = Cookies.get("_fbc");
        const fbp = Cookies.get("_fbp");

        if (
          utmSource ||
          utmMedium ||
          utmCampaign ||
          utmContent ||
          irclickid ||
          fbc ||
          fbp ||
          fbclid ||
          pageVisits
        ) {
          const payload = {};
          payload.utm_source = utmSource;
          payload.utm_medium = utmMedium;
          payload.utm_campaign = utmCampaign;
          payload.utm_content = utmContent;
          payload.irclickid = irclickid;
          payload.fbc = fbc;
          payload.fbp = fbp;
          payload.fbclid = fbclid;
          payload.page_visits = pageVisits;
          payload.user_id = createAccountResult.id;
          payload.created_by = "api";
          payload.tracking_source = window.location.href;

          yield fork(trackCampaign, payload, sessionToken); // async dispatch
        }
      } catch (e) {}
      // Set parent data
      yield put(setParentInfo(createAccountResult));

      // Fetch braintree client token
      try {
        const braintreeClientTokenResponse = yield axios({
          method: "post",
          baseURL,
          url: "/v1/paymentmethods/client_token",
          headers: { Authorization: `JWT ${sessionToken}` },
          data: { userId: sessionUserId },
        });
        const braintreeClientTokenResult = braintreeClientTokenResponse.data;
        const braintreeClientToken = braintreeClientTokenResult.clientToken;
        // Set app braintree client token...
        yield put(setBraintreeClientToken(braintreeClientToken));
      } catch (error) {
        // ...
      }

      const students = yield select(selectStudents);
      const cart = yield select(storeCart);

      if (students && students.length > 0) {
        const session = yield select(selectSession);

        const sessionToken = session.token;
        const sessionUserId = session.userId;
        const plans = yield select(selectPlans);
        const selectedBillingCycle = yield select(selectSelectedBillingCycle);
        let selectedGradeLevel = "";
        for (let i = 0; i < students.length; i++) {
          const student = students[i];
          selectedGradeLevel = gradeUtil.range(student.grade);

          // Fix for India
          if (
            geoLocation &&
            geoLocation.country_code &&
            geoLocation.country_code === "IN"
          ) {
            selectedGradeLevel = "1 - 6";
          }

          yield put(
            selectPlan({
              selectedGradeLevel,
              selectedService: service,
              name: student.first_name,
            })
          );
        }

        // Create Students
        const savedStudents = yield axios.all(
          createStudents(
            sessionUserId,
            sessionToken,
            students,
            plans,
            selectedService,
            selectedBillingCycle,
            selectedGradeLevel
          )
        );

        // Get Only Data
        let studentInfo = [];
        studentInfo = savedStudents.map((student) => student.data);

        window.dataLayer.push({
          event: 'students_account_created',
          students: studentInfo,
        });

        // Save
        yield put(setStudentInfo(studentInfo));

        // yield put(startSubmit('StudentInformationForm'));
        // Completed Both Steps
        yield put(loadingAction(false));

        // Redirect
        let path = "/step-3";
        routerHistory.push({
          pathname: path,
          search: window.location.search,
        });
      } else if (cart.items.length > 0) {
        let purchaseCategory = "Math";
        if (cart.items[0] && cart.items[0].category) {
          purchaseCategory = cart.items[0].category;
        }
        if (purchaseCategory === "SummerProgram") {
          yield put(storeCartAddDiscount({ discount: 20, type: "percentage" })); //25% Discount
        }
        let path = "/step-3-store";
        if (purchaseCategory === "TutoringSession") {
          path = "/step-2-store";
        }
        yield put(loadingAction(false));
        routerHistory.push({
          pathname: path,
          search: window.location.search,
        });
      } else {
        // Get Current URL
        let url = window.location.href;
        // Check if Registration Flow is present
        yield put(loadingAction(false));
        let path = "/step-2";
        if (url.indexOf(constants.REGISTRATION_FLOW_ID) !== -1) {
          path = "/" + constants.REGISTRATION_FLOW_ID + "/students";
        }
        // Redirect

        routerHistory.push({
          pathname: path,
          search: window.location.search,
        });
      }
    } catch (error) {
      yield put(loadingAction(false));
      yield put(
        errorAction({
          error,
          title: "Error submitting student information form",
        })
      );
      // Try Hotjar
      try {
        if (window && window.hj) {
          window.hj("formSubmitFailed");
        }
      } catch (error) {
        // ...
      }
    }
  }
}

function createStudents(
  sessionUserId,
  sessionToken,
  students,
  plans,
  selectedService,
  selectedBillingCycle,
  selectedGradeLevel
) {
  const requests = [];
  for (let i = 0; i < students.length; i++) {
    const student = students[i];
    const first_name = student.first_name;
    const last_name = student.last_name;
    // let service = selectedService[0].selectedService;
    const service =
      selectedService && selectedService.length > 0
        ? selectedService[0].selectedService
        : "Gold";
    const billingCycle = selectedBillingCycle
      ? parseInt(selectedBillingCycle)
      : 1;
    const selectedPlan = plans[selectedGradeLevel][service][billingCycle];
    requests.push(
      axios({
        method: "post",
        baseURL,
        url: `/v1/parents/${sessionUserId}/students`,
        headers: { Authorization: `JWT ${sessionToken}` },
        data: {
          first_name: first_name,
          last_name: last_name,
          gender: student.gender || "",
          braintree: {
            addon_id: selectedPlan._id,
            billingFrequency: 1,
            gradeRange: selectedGradeLevel,
          },
          service_id: selectedPlan.cf_service_id,
          grade: student.grade,
        },
      })
    );
  }
  return requests;
}
