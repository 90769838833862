import React, { Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import App from "../App";
const StudentInfo = React.lazy(() => import("../StudentInfo"));
const AccountInfo = React.lazy(() => import("../AccountInfo"));
const PaymentRegular = React.lazy(() => import("../PaymentRegular"));

// import Pricing from '../Pricing';
const MathPrograms = React.lazy(() => import("../MathPrograms"));
const MathProgramsiPad = React.lazy(() => import("../MathProgramsiPad"));
const ContinueInfo = React.lazy(() => import("../ContinueInfo"));
const NotFound = React.lazy(() => import("../NotFound"));
const ThankYouFree = React.lazy(() => import("../ThankYouFree"));
const ThankYouFreeDT = React.lazy(() => import("../ThankYouFreeDT"));
const ThankYouWebSA = React.lazy(() => import("../ThankYouWebSA"));
const ThankYouLite = React.lazy(() => import("../ThankYouLite"));
const onEnterStep2 = React.lazy(() => import("./onEnterStep2"));
// import onEnterStep3 from './onEnterStep3';
const onEnterThankyou = React.lazy(() => import("./onEnterThankyou"));

// Flow Id - Tour
const TourStudentInfo = React.lazy(() => import("../TourStudentInfo"));
const TourAccountInfo = React.lazy(() => import("../TourAccountInfo"));
const onEnterStudents = React.lazy(() => import("./onEnterStudents"));

// Store Front
const StoreDisplay = React.lazy(() => import("../StoreDisplay"));
const StoreAccountInfo = React.lazy(() => import("../StoreAccountInfo"));
const StoreStudentInfo = React.lazy(() => import("../StoreStudentInfo"));
const StorePayment = React.lazy(() => import("../StorePayment"));
const ThankYouStore = React.lazy(() => import("../ThankYouStore"));
const ThankYouSummerProgram = React.lazy(() =>
  import("../ThankYouSummerProgram")
);
const ThankYouTutoringSession = React.lazy(() =>
  import("../ThankYouTutoringSession")
);
const ThankYouArtOfWordProblem = React.lazy(() =>
  import("../ThankYouArtOfWordProblem")
);

// Tutoring Sessions
// const TutoringSessions = React.lazy(() => import("../TutoringSessions"));
// const CustomRedirect = React.lazy(() => import("../CustomRedirect"));
const StudentInfoCampaign = React.lazy(() => import("../StudentInfoCampaign"));
const constants = React.lazy(() =>
  import("../../constants/defaultRegistrationFlow")
);
const ThankYouWebRedirect = React.lazy(() => import("../ThankYouWebRedirect"));
const PrerequisiteFoundationDisplay = React.lazy(() => import("../PrerequisiteFoundationDisplay"));

/*
  For Step 3, there are 2 possible configurations:
  1. Shopping cart has ANY paid plans (includes mix of paid and test drive)
      - If so, we need to show payment method
      - Use {Payment}
  2. Shopping cart has ONLY test drive plans
      - If so, there is no need for credit card info
      - Use {OrderReview}
*/
// Flow Constants
const routeParentConstant = `/${constants.REGISTRATION_FLOW_ID}/parent`;
const routeStudentsConstant = `/${constants.REGISTRATION_FLOW_ID}/students`;

const routes = () => (
  <Suspense
    fallback={
      <div className="c-main-loader">
        <div className="c-main-loader__screen"></div>
      </div>
    }
  >
    <App>
      <Switch>
        <Route exact path="/pricing-math-programs" component={MathPrograms} />
        <Redirect exact path="/" to="pricing-math-programs" />
        <Route
          path="/pricing-math-programs-ipad"
          component={MathProgramsiPad}
        />

        <Route path="/continue" component={ContinueInfo} />
        <Route path="/step-1" component={AccountInfo} />
        <Route path="/step-2" component={StudentInfo} onEnter={onEnterStep2} />
        <Route path="/step-3" component={PaymentRegular} />
        <Route path="/step-2-campaign" component={StudentInfoCampaign} />

        {/* New Registration Flow  BREAK*/}
        <Route path={routeParentConstant} component={TourAccountInfo} />
        <Route
          path={routeStudentsConstant}
          component={TourStudentInfo}
          onEnter={onEnterStudents}
        />

        {/* Store Registration Flow BREAK */}
        <Route path="/step-1-store" component={StoreAccountInfo} />
        <Route path="/step-2-store" component={StoreStudentInfo} />
        <Route path="/step-3-store" component={StorePayment} />
        <Route path="/math-worksheets-store" component={StoreDisplay} />
        <Route path="/prerequisite-foundation-courses" component={PrerequisiteFoundationDisplay} />
        <Route
          path="/thank-you-store"
          component={ThankYouStore}
          onEnter={onEnterThankyou}
        />
        <Route
          path="/thank-you-summer-program"
          component={ThankYouSummerProgram}
          onEnter={onEnterThankyou}
        />
        <Route
          path="/thank-you-art-of-word-problem"
          component={ThankYouArtOfWordProblem}
          onEnter={onEnterThankyou}
        />

        {/* Tutoring Sessions Flow BREAK */}
        <Route path="/tutoring-sessions" component={() => { window.location.href = 'https://hellothinkster.com/high-school-math-tutoring.html'; return (<div>Redirecting...</div>);}} />
        <Route path="/high-school-tutoring" component={() => { window.location.href = 'https://hellothinkster.com/high-school-math-tutoring.html'; return (<div>Redirecting...</div>);}} />
        <Route
          path="/thank-you-tutoring-session"
          component={ThankYouTutoringSession}
          onEnter={onEnterThankyou}
        />

        {/* <Route path="/step-3" component={EnterPaymentInformation} /> BREAK */}
        <Route
          path="/ref-thank-you"
          component={ThankYouFree}
          onEnter={onEnterThankyou}
        />
        <Route path="/thank-you-free-dt" component={ThankYouFreeDT} />
        <Route
          path="/thank-you-web-sa"
          component={ThankYouWebSA}
          onEnter={onEnterThankyou}
        />
        <Route path="/thank-you-web" component={ThankYouWebSA} />
        <Route path="/thank-you-web-redirect" component={ThankYouWebRedirect} />
        <Route
          path="/thank-you-lite"
          component={ThankYouLite}
          onEnter={onEnterThankyou}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </App>
  </Suspense>
);

export default routes;
